.create-client-account-button {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 3px;
}

.create-client-account-button button {
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;

    background-color: lightgray;
    color: black;
    border: 2px solid lightgray;
}

.create-client-account-button button:hover {
    background: rgb(179, 179, 179);
    color: black;
    border: 2px solid rgb(179, 179, 179);
}