.login-box {
    /* background: rgb(114, 171, 209);
    background-image: url(../../resources/images/login-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed; */
}

.login-box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #fff;
}

.login-container {
    width: 450px;
    display: flex;
    flex-direction: column;
    padding: 0 15px 0 15px;
    margin-bottom: 10%;
}

.top-header header {
    color: #000000a8;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    padding: 10px 0 10px 0;
}

.input-field {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
}

::-webkit-input-placeholder {
    color: #000000a8;
}

.errorMessage {
    text-align: center;
    color: red;
}

.password-forget-email {
    text-align: center;
    color: gray;
}

.login-welcome-nav {
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0rem calc((100vw - 1300px) / 2);
}

.login-logo {
    color: #2c7abe;
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
    padding: 0 1rem;
    padding-top: 30px;
}
