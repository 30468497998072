.custom-report-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.custom-report-header .top-header {
    width: 100%;
}

.template-button-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    justify-content: flex-end;
}

.template-button-section button{
    margin-top: 20px;
    padding: 10px 0;
    border-radius: 5px;
    cursor: pointer;

    width: 100px;
}

.template-button-section button:hover{
    opacity: 0.9;
}

.grid-list {
    display: grid;
    gap: 20px;
}

.template-name {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    gap: 5px;
}

.template-name .input-field {
    padding: 0;
    padding-block-end: 10px;
}

.template-name .input-field input {
    height: 32px;
}

.category-card {
    padding: 30px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    height: fit-content;
}

.category-card header {
    color: #2c7abe;
    font-size: 18px;
    font-weight: bold;
}

.category-headers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding-block-start: 15px;
}

.category-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block-end: 10px;
}

.template-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
}

.template-type {
    margin-top: 8px;
}

.type-button {
    border-radius: 3px;
}


/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/
@media (min-width: 575px) {
    .report-template-container {
        max-width: 540px;
        width: 100%;
        margin-inline: auto;
    }
}

@media (min-width: 768px) {

    :root {
        --section-padding: 120px;
    }

    .report-template-container {
        max-width: 720px;
    }

    .grid-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 992px) {
    .report-template-container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .report-template-container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .report-template-container {
        max-width: 1400px;
    }
}