body {
    background: #f0f0f07b;
}

.password-change-container {
    display: flex;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    justify-content: center;
}

.password-change {
    width: 500px;
    padding: 20px;
}

.password-change-top-header header {
    color: #000000a8;
    font-size: 30px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 0;
}

.password-change-top-header h3 {
    color: #000000a8;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    padding: 10px 0 30px 0;
}

.errorMessage {
    text-align: center;
    font-weight: 500;
    text-align: center;
    color: rgba(173, 43, 43, 0.726);
}