.ben-section-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 5px;
}

.add-ben-button{
    display: flex;
    justify-content: center;
}