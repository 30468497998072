.verify-button-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.verify-button-section button{
    margin-top: 20px;
    padding: 10px 0;
    border-radius: 5px;
    cursor: pointer;

    width: 100%;
}

.qrCode {
    display: flex;
    justify-content: center;
}

.qrCode img {
    height: 300px;
    width: 300px;
}