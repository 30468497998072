.password-validator-main {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    margin-bottom: 5px;
}

.password-validator {
    display: flex;
    flex-direction: column;
    font-size: 15px;
}

.password-category p {
    color: gray;
}

.password-category-valid p {
    color: #2c7abe;
}

.password-category-invalid p {
    color: rgba(173, 43, 43, 0.837);
}