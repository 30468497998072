.support-header {
    padding-top: 50px;
}

.support-header header {
    font-size: 25px;
}

.advisor-search-dropdown {
    flex-grow: 2;
}

.upload button {
    height: 100%;
}


.support-error {
    display: flex;
    color:red;
    justify-content: center;
    padding-top: 20px;
}