.welcome-nav {
    height: 70px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid lightgray;
}

@media screen and (max-width:768px) {
    .welcome-nav {
        width: fit-content;
    }
}

.logo {
    color: #2c7abe;
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
    padding: 0 1rem;
}

.logo:hover {
    color: #000;
    transition: .3s;
}

.welcome-nav a {
    text-decoration: none;
    color: #2c7abe;
    padding: 0 1.5rem;
    font-weight: 600;
}

.nav-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.nav-item:hover {
    color: #000;
    transition: .3s;
}

.logout {
    /* margin-top: 20px; */
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    width: 100%;
    background-color: white;
    color: #2c7abe;
    border: 2px solid #2c7abe;
}

.logout:hover {
    font-weight: 1000;
}