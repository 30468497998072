.forms-main {
    display: grid;
    gap: 20px;
}

.form-viewer {
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    background-color: white;
    min-height: 100vh;
}

.form-viewer-header {
    padding: 7px;
    background-color: #2c7abe;
    color: white;
}
 
/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/
@media (min-width: 575px) {
    .forms-container {
        max-width: 540px;
        width: 100%;
        margin-inline: auto;
    }
}

@media (min-width: 768px) {

    :root {
        --section-padding: 120px;
    }

    .forms-container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .forms-container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .forms-container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .forms-container {
        max-width: 1600px;
    }

    .forms-main {
        grid-template-columns: 0.4fr 1fr;
    }
}

@media (min-width: 1600px) {
    .forms-container {
        max-width: 1800px;
    }
}

@media (min-width: 1800px) {
    .forms-container {
        max-width: 2000px;
    }
}