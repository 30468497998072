.input-field {
    padding: 5px;
}

.input-field label {
    font-weight: bold;
}

.input-field input {
    display: block;
    height: 30px;
    background-color: white;
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    border: 1px solid rgba(0, 0, 0, 0.555);
    color: #000;
}

.input-field select {
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;  
    background-size: 12px;
    display: block;
    height: 30px;
    background-color: white;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    border: 1px solid rgba(0, 0, 0, 0.555);
    -webkit-border: 1px solid rgba(0, 0, 0, 0.555);
}

.input-field select::before {
    border: 1px solid rgba(0, 0, 0, 0.555);
    -webkit-border: 1px solid rgba(0, 0, 0, 0.555);
  }
  
  .input-field select::after {
    border: 1px solid rgba(0, 0, 0, 0.555);
    -webkit-border: 1px solid rgba(0, 0, 0, 0.555);
  }

.input-field button {
    margin-top: 20px;
    padding: 10px 0;
    border-radius: 5px;
    cursor: pointer;

    width: 100%;
    background-color: #2c7abe;
    color: white;
    border: 2px solid #2c7abe;
}

.input-field button:hover{
    opacity: 0.8;
}

.input-field .no-value {
    background-color: #fff9db;
}

.input-field button:disabled,
.input-field button[disabled]{
  border: 1px solid #cccccc;
  background-color: #cccccc;
  color: #666666;
}

.file-table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;

    text-align: left;
    width: 100%;
}

.file-table.empty {
    padding-top: 40px;
    padding-bottom: 40px
}

.file-table th {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom: 1px solid #ddd;
}

.file-table td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom: 1px solid #ddd;
}

.file-table tr:last-child td {
    border-bottom: none;
}

.file-table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    padding-block: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.file-table-container {
    padding-block-start: 10px;
}

.file-table-header header {
    color: #2c7abe;
    font-size: 18px;
    font-weight: bold
}

.file-table-header .file-table-header-field button {
    width: 80px;
}

.file-table-container .file-table {
    border-top: none;
}

.file-table-container .file-table .file-table-header-field button {
    color: #2c7abe;
    background-color: white;
}

.file-table-header-field button {
    padding: 10px 0;
    border-radius: 5px;
    cursor: pointer;

    width: 100%;
    background-color: #2c7abe;
    color: white;
    border: 2px solid #2c7abe;
}

.file-table-header-field button:hover{
    opacity: 0.9;
}

.confirm-btn {
    background-color: #2c7abe;
    color: white;
    border: 1px solid #2c7abe;
}

.cancel-btn {
    background-color: white;
    color: #2c7abe;
    border: 1px solid #2c7abe;
}

.confirm-button-section button:hover{
    opacity: 0.9;
}

.search-dropdown {
    flex-grow: 2;
}

.error-message {
    display: flex;
    color:red;
    justify-content: center;
    padding-top: 20px;
}


/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/
@media (min-width: 575px) {
    .table-page-container {
        max-width: 540px;
        width: 100%;
        margin-inline: auto;
    }
}

@media (min-width: 768px) {

    :root {
        --section-padding: 120px;
    }

    .table-page-container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .table-page-container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .table-page-container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .table-page-container {
        max-width: 1320px;
    }
}