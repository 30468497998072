.file-details-controls {
    display: grid;
    gap: 50px;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
}

.control {
    width: 100%;
}

.control-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.control-button button {
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;

    width: 100%;
    background-color: #2c7abe;
    color: white;
    border: 2px solid #2c7abe;
}

.control-button  button:disabled,
.control-button  button[disabled]{
  border: 1px solid #cccccc;
  background-color: #cccccc;
  color: #666666;
}

.file-details-inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0px;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    background: white;
}

.inputs-container {
    width: 100%;
}

.inputs-header {
    display: grid;
    gap: 10px;
    padding: 0 10px;
}

.inputs-header select {
    min-width: 300px;
    font-weight: bold;
    font-size: medium;
    padding: 5px;
}

.inputs-header-client-account {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.delete-client-account-button {
    padding: 10px 10px;
    border-radius: 3px;
    cursor: pointer;

    width: 120px;
    background-color: rgba(173, 43, 43, 0.837);
    color: white;
    border: 2px rgba(173, 43, 43, 0.837);
}

.settings-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 100%;
    gap: 10px;
}

.file-shortcuts {
    display: none;
    width: 200px;
    padding-right: 5px;
    border-right: 1px solid lightgray;
}

.input-form {
    padding: 0 10px;
    width: 100%;
    overflow-y: scroll;
    max-height: 700px;
}

.input-form-button {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 3px;
    padding-right: 20px;
}

.input-form-button button {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    width: 200px;
    background: rgb(27,122,47);
    background: linear-gradient(90deg, rgba(27,122,47,1) 0%, rgba(21,165,33,1) 100%, rgba(21,165,34,1) 100%, rgba(21,164,32,1) 100%, 
                rgba(25,180,54,1) 100%, rgba(0,255,64,1) 100%, rgba(20,180,60,1) 100%, rgba(59,181,13,1) 100%);border: 1px solid rgb(27,122,47);
    border: 1px solid rgb(27,122,47);

    font-size: large;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-height: 45px !important
}

.input-form-button button:hover {
    opacity: 0.7;
    border: 1px solid rgb(27,122,47) !important;
    color: white !important;
}

.category-section {
    padding-top: 10px;
    padding-bottom: 20px;

    max-height: 55px;
    overflow: hidden;
}

.category-section.expanded {
    padding-top: 10px;
    padding-bottom: 20px;

    max-height: max-content;
}

.category-btn {
    font-size:medium;
    color: white;
    font-weight: bold;

    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}

.category-btn .icon {
    transition: 0.25s ease;
}

.category-section.expanded .category-btn .icon {
    transform: rotate(0.5turn);
}

.category-title {
    background-color: #2c7abe;
    border-radius: 3px;
    border-bottom-right-radius: 50px;
    padding-left: 10px;
}

.category-title button {
    background: none;
    border: none;
    width: 100%;
    padding-block: 10px;
}

.user-inputs {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
    justify-content: left;
    flex-shrink: 0;
    flex-flow: row wrap;
    padding-block-start: 20px;
}

/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/
@media (min-width: 575px) {
    .file-details-page-container {
        max-width: 540px;
        width: 100%;
        margin-inline: auto;
    }
}

@media (min-width: 768px) {

    :root {
        --section-padding: 120px;
    }

    .file-details-page-container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .file-details-page-container {
        max-width: 960px;
    }

    .file-details-controls {
        grid-template-columns: 1fr 1fr;
    }

    .inputs-header {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 1200px) {
    .file-details-page-container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .file-details-page-container {
        max-width: 1320px;
    }

    .file-shortcuts{
        display:block;
    }
}

@media (min-width: 1600px) {
    .file-details-page-container {
        max-width: 1520px;
    }
}

@media (min-width: 1800px) {
    .file-details-page-container {
        max-width: 1720px;
    }
}

@media (min-width: 2000px) {
    .file-details-page-container {
        max-width: 1920px;
    }
}