.custom-report-header {
    padding-top: 50px;
    padding-bottom: 10px;
}

.custom-report-header header {
    font-size: 25px;
}

.custom-report-field button {
    padding: 10px 0;
    border-radius: 5px;
    cursor: pointer;

    width: 100%;
    background-color: #2c7abe;
    color: white;
    border: 2px solid #2c7abe;
}

.custom-report-field button:hover{
    opacity: 0.9;
}

.create-report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.create-report .custom-report-field button {
    width: 170px;
}