body {
    background: #f0f0f07b;
}

.user-main {
    width: 100%;
}

.user-container {
    display: flex;
    width: 100%;
    padding-top: 20px;
    justify-content: center;
}

.create-user {
    width: 500px;
    padding: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(16, 2, 3, 0.108);
    height: fit-content;
}

.reset-user {
    width: 500px;
    padding: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(16, 2, 3, 0.108);
    height: fit-content;
}

.reset-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reset-search{
    padding-top: 5px;
    padding-bottom: 15px;
}

.errorMessage {
    text-align: center;
    font-weight: 500;
    text-align: center;
    color: rgba(173, 43, 43, 0.726);
}

.support-user {
    width: 600px;
    padding: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(16, 2, 3, 0.108);
    height: fit-content;
}

.support-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.support-user-table {
    text-align: left;
    width: 100%;
}

.support-user-table th {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom: 1px solid #ddd;
}

.support-user-table td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom: 1px solid #ddd;
}

.support-user-table tr:last-child td {
    border-bottom: none;
}
