body {
    background: #f0f0f07b;
}

.table-container {
    padding-top: 50px;
    width: 100%;
    margin-left:auto; 
    margin-right:auto;
}

.table-button {
    width: 100%;
    color: #080710;
    padding: 4px 9px 4px 9px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.127);
}

.table-button:hover {
    background-color: #2c7abe;
    color: white;
}

.table-delete-button {
    width: 100%;
    color: #080710;
    padding: 4px 9px 4px 9px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.127);
}

.table-delete-button:hover {
    background-color: rgba(173, 43, 43, 0.837);
    color: white;
}

.completed{
    color: green;
    font-weight: 600;
}

.needsUpdate{
    color: red;
    font-weight: 600;   
}