@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Quicksand', sans-serif;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.file-process-container {
    width: 500px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 0 15px 0 15px;
    margin-bottom: 10%;
}

.file-process-container * {
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.file-process-container h4{
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

.file-process-container p{
    font-weight: 500;
    line-height: 42px;
    text-align: center;
    margin-bottom: 20px;
    color: rgba(173, 43, 43, 0.726);
}


.file-process-container label{
    display: block;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
}

.file-process-container button{
    margin-top: 20px;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    
}

.process {
    width: 100%;
    background-color: #2c7abe;
    color: white;
    border: 2px solid #2c7abe;
}

.process:hover{
    border: 2px solid rgba(255, 255, 255, 0.622);
    box-shadow: 0 0 10px rgba(52, 129, 230, 0.705);
}

.delete {
    width: 50%;
    background-color: rgba(173, 43, 43, 0.837);
    color: white;
    border: 2px solid rgba(173, 43, 43, 0.837);
    margin-left: 10px;
}

.delete:hover {
    border: 2px solid rgba(255, 255, 255, 0.622);
    box-shadow: 0 0 10px rgba(173, 42, 55, 0.705);
}

.file-process-container select{
    display: block;
    height: 50px;
    width: 100%;
    background-color: white;
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}

.inputBox {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}

.inputBox .hyperlink {
    cursor:pointer;
    color:#2c7abe;
    text-decoration:underline;
}


.advisor-select select {
    font-weight: 500;
}

.advisor-select input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: white;
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    border: 1px solid rgba(0, 0, 0, 0.555);
    color: #000;
}