.forms-header {
    padding-top: 50px;
    padding-bottom: 10px;
}

.forms-header header {
    font-size: 25px;
}

.generate-forms {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.generate-forms-field button {
    padding: 10px 0;
    border-radius: 5px;
    cursor: pointer;

    width: 150px;
    background-color: #2c7abe;
    color: white;
    border: 2px solid #2c7abe;
}

.generate-forms-field button:hover{
    opacity: 0.9;
}

.tab-btn {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    font-size: 17px;

    text-align: left;
    width: 100%;
/* 
    padding-block: 16px;
    padding-left: 16px; */
  }