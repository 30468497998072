.pdf-main {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    gap: 5px;
}

.pdf-validations {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.validation-tag {
    text-align: center;
    line-height: 40px;
    height: fit-content;
    width: 100%;
}

.pdf {
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
}

.pdf-utils {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: right;
    padding: 6px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    gap: 5px;
}

.zoom-control {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.pdf-utils button {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;

    padding: 5px 0px;
    border-radius: 5px;
    cursor: pointer;

    width: 100px;
    background-color: white;
    color: #2c7abe;
    border: 1px solid #2c7abe;
    transition: .3s;
}

.pdf-utils button:hover{
    background-color: #2c7abe;
    color: white;
    border: 1px solid #2c7abe;
}

.pdf-utils button:disabled,
.pdf-utils button[disabled]
{
    border: 1px solid #cccccc;
    background-color: #cccccc;
    color: #666666;
}